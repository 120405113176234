import LocomotiveScroll from 'locomotive-scroll';

// const locomotiveInstance = new LocomotiveScroll({
//     el: document.querySelector('[data-scroll-container]'),
//     smooth: true,
//     lerp: 0.1,
//     multiplier: 1,
// });

const links = document.querySelectorAll('.menu-item')
const cursor = document.querySelector('.cursor')
const containers = document.querySelectorAll('.thumbnail')

document.querySelector('.listContainer').addEventListener('mouseover',() => {
    document.querySelector('.cursor').style.display = "none";
})

document.querySelector('.listContainer').addEventListener('mouseout',() => {
    document.querySelector('.cursor').style.display = "initial";
})

document.querySelector('.skillsContainer').addEventListener('mouseover',() => {
    document.querySelector('.cursor').style.display = "none";
})

document.querySelector('.skillsContainer').addEventListener('mouseout',() => {
    document.querySelector('.cursor').style.display = "initial";
})


links.forEach((link) => {
    let txt = link.querySelector('.caption')
    let shapeA = link.querySelector('.shape1')
    let shapeB = link.querySelector('.shape2')
    let shapeC = link.querySelector('.shape3')


    link.addEventListener('mouseover', () => {
        txt.classList.add('color')
        shapeA.classList.add('autoHeight1')
        shapeB.classList.add('autoHeight2')
        shapeC.classList.add('autoHeight3')
        cursor.classList.remove('rotateReverse')
        cursor.classList.add('cursor-hover', 'rotate')

    })

    link.addEventListener('mouseout', () => {
        txt.classList.remove('color')
        shapeA.classList.remove('autoHeight1')
        shapeB.classList.remove('autoHeight2')
        shapeC.classList.remove('autoHeight3')
        cursor.classList.add('rotateReverse')
        cursor.classList.remove('cursor-hover', 'rotate')
    })
})


function resizeImage() {
    containers.forEach(container => {
        const left = container.querySelector('h2')
        const right = container.querySelector('.imgContainer')

        right.style.height = window.innerWidth > 768 ? `${left.clientHeight}px` : '250px';
    })

}

function setCustomCursorPosition() {
    let mouseX = event.clientX; // Gets Mouse X
    let mouseY = event.clientY; // Gets Mouse Y

    cursor.style.top = `${mouseY}px`
    cursor.style.left = `${mouseX}px`
}

document.addEventListener("mousemove", setCustomCursorPosition);

resizeImage();
window.onresize = resizeImage;

new ResizeObserver(() => scroll.update()).observe(document.querySelector("[data-scroll-container]"))

const imagesLoaded = require("imagesloaded"); // import the library or can use cdn


  let scrollContainer = document.querySelector("[data-scroll-container]");

  var scroll;

  scroll = new LocomotiveScroll({
    el: scrollContainer,
    smooth: true,
    lerp:0.08,
    getSpeed: true,
    getDirection: true,
  });

  /* update scroll (height) when all images are loaded */

  imagesLoaded(scrollContainer, { background: true }, function () {
    scroll.update();
  });
